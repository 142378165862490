import { createRouter, createWebHistory } from 'vue-router'

// 路由数组
const routes = [
    {
        path: '/',
        component: ()=>import('../view/index.vue'),
        children: [
            {
                path: '',
                component: ()=>import('../view/home/index.vue'),
                name: '教务首页',
                meta: {
                    title: '教务首页'
                }
            },
            {
                path: 'drawing',
                component: ()=>import('../view/midjourney/index.vue'),
                name: '绘图',
                meta: {
                    title: '绘图'
                }
            },
            {
                path: 'chat',
                component: ()=>import('../view/chat/index.vue'),
                name: 'chatgpt',
                meta: {
                    title: 'chatgpt'
                }
            },
            {
                path: 'user',
                component: ()=>import('../view/user/index.vue'),
                name: '个人中心',
                meta: {
                    title: '个人中心'
                }
            },
        ]
    },
    {
        path: '/confession',
        component: ()=> import('../view/confession/index.vue'),
        children: [
            {
                path: 'home',
                component: ()=> import('../view/confession/home/index.vue'),
                name: '叨叨墙',
                meta: {
                    title: '叨叨墙'
                }
            },
            {
                path: 'sale',
                component: ()=> import('../view/confession/sale/index.vue'),
                name: '卖舍友',
                meta: {
                    title: '卖舍友'
                }
            }
        ]
    },
    {
        path: '/schoolDelivery',
        component: ()=> import('@/view/work/index.vue'),
        children: []
    },
    {
        path: '/lost',
        component: () => import('@/view/lost/index.vue'),
        children: [
            {
                path: 'home',
                component: () => import('@/view/lost/home/index.vue'),
                name: '失物招联',
                meta: {
                    title: '失物招领'
                }
            },
            {
                path: 'square',
                component: () => import('@/view/lost/square/index.vue'),
                name: '广场',
                meta: {
                    title: '广场'
                }
            }
        ]
    },
    {
        path: '/lost/publish',
        component: ()=> import('@/view/lost/publish/index.vue'),
        name: '发布',
        meta: {
            title: '发布',
            keepAlive: true
        }
    },
    {
        path: '/work',
        component: ()=>import('../view/work/index.vue'),
        children: [
            {
                path: 'home',
                component: ()=> import('@/view/work/home/index.vue'),
                name: '兼职首页',
                meta: {
                    title: '兼职首页'
                }
            },
            {
                path: 'square',
                component: ()=> import('@/view/work/square/index.vue'),
                name: '兼职广场',
                meta: {
                    title: '兼职广场'
                }
            },
            {
                path: 'my',
                component: ()=> import('@/view/work/my/index.vue'),
                name: '我的',
                meta: {
                    title: '我的'
                }
            }
        ]
    },
    {
        path: '/workDetail',
        component: ()=> import('@/view/work/workDetail/index.vue'),
        name: '职位详情',
        meta: {
            title: '职位详情'
        }
    },
    {
        path: '/signUpSuccess',
        component: () => import('@/view/work/signupsuccess/index.vue'),
        name: '报名成功',
        meta: {
            title: '报名成功'
        }
    },
    {
        path: '/myWorkList',
        component: ()=> import('@/view/work/mywork/index.vue'),
        name: '我的报名',
        meta: {
            title: '我的报名'
        }
    },
    {
        path: '/signUpDetail',
        component: ()=> import('@/view/work/signupdetail/index.vue'),
        name: '报名单详情',
        meta: {
            title: '报名单详情'
        }
    },
    {
        path: '/saleDetail',
        component: ()=> import('../view/confession/sale/detail/index.vue'),
        name: '详情',
        meta: {
            title: '详情'
        }
    },
    {
        path: '/confession/saleAdd',
        component: ()=> import('../view/confession/sale/saleadd/index.vue'),
        name: '信息发布',
        meta: {
            title: '发布'
        }
    },
    {
        path: '/confession/homeAdd',
        component: ()=> import('../view/confession/home/add/index.vue'),
        name: '朋友圈发布',
        meta: {
            title: '发布'
        }
    },
    {
        path: '/schoolBind',
        component: () => import('../view/myschool/bind.vue'),
        name: '学校绑定',
        meta: {
            title: '学校绑定'
        }
    },
    {
        path: '/curriculum',
        component: () => import('../view/curriculum/index.vue'),
        name: '学校课表',
        meta: {
            title: '学校课表'
        }
    },
    {
        path:"/messageAdd",
        component: () => import('../view/confession/home/add/index.vue'),
        name: '叨一叨',
        meta: {
            title: '叨一叨'
        }
    },
    {
        path:"/getLocationFromMap",
        component:() => import('@/view/getLocationFromMap/index.vue'),
        name: '地图选址',
        meta: {
            title: '地图选址'
        }
    },
    {
        path: "/usercj",
        component: ()=> import('@/view/mycj/index.vue'),
        name: '我的成绩',
        meta: {
            title: '我的成绩'
        }
    },
    {
        path: '/charge',
        component: () => import('@/view/charge/index.vue'),
        name: '商品选购',
        meta: {
            title: '商品选购'
        }
    },
    {
        path: '/userInfo',
        component: () => import('@/view/user/userInfo/index.vue'),
        name: '用户信息',
        meta: {
            title: '用户信息'
        }
    },
    {
        path: '/myAi',
        component: () => import('@/view/user/myai/index.vue'),
        name: '我的AI',
        meta: {
            title: '我的AI'
        }
    },
    {
        path: '/mySchool',
        component: () => import('@/view/user/myschool/index.vue'),
        name: '我的学校',
        meta: {
            title: '我的学校'
        }
    },
    {
        path: '/feedback',
        component: ()=> import('@/view/feedback/index.vue'),
        name: '意见反馈',
        meta: {
            title: '意见反馈'
        }
    },
    {
        path: '/woodenfish',
        component: ()=> import('@/view/woodenFish/index.vue'),
        name: '电子木鱼',
        meta: {
            title: '电子木鱼'
        }
    },
    {
        path: '/mjPrompt',
        component: ()=> import('@/view/mjPrompt/index.vue'),
        name: 'MJ Prompt-参考',
        meta: {
            title: 'MJ Prompt-参考'
        }
    }
]

// 创建路由对象
const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to) => {
    window.document.title = to.meta.title
})

export default router