<template>
  <router-view v-if="$route.meta.keepAlive" v-slot="{ Component }">
    <transition name="router-fade" mode="out-in">
      <keep-alive>
        <component :is="Component"/>
      </keep-alive>
    </transition>
  </router-view>
  <router-view v-if="!$route.meta.keepAlive"></router-view>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    this.setAppHeight()
    console.log("设置成功")
  },
  methods: {
    setAppHeight() {
      var app = document.getElementById("app")
      app.style.height = window.innerHeight + "px"
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: white;
}
body {
  margin: unset !important;
}
.van-floating-bubble {
  .van-icon {
    font-size: 2.6rem!important;
  }
}
.van-popup {
  .van-popover__content {
    width: 5rem !important;
    .van-popover__action {
      width: 100% !important;
      .van-icon {
        font-size: .8rem !important;
      }
      .van-popover__action-text {
        font-size: smaller !important;
      }
    }
  }
}
.n4 {
  color: transparent;
  position: absolute;
  top: 40%;
  left: calc(50% - 27px);
  animation: yan 1s linear;
}
@keyframes yan {
  0% {
    color: transparent;
  }

  100% {
    color: white;
    transform: translate(0px, -100px);
  }
}
</style>
