import Vuex from 'vuex'
const state = {
    name: "test",
    latitude: '',
    longitude: ''
}
const mutations = {
}
const getters = {
}
export default new Vuex.Store({
    state,
    mutations,
    getters
})
