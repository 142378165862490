import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store";
import 'vant/lib/index.css';
import '@/assets/custom-icon/iconfont.css';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css' //样式
import {
    Button,
    Swipe,
    SwipeItem,
    Image,
    Tabbar,
    TabbarItem,
    Field,
    FloatingBubble,
    Uploader,
    Popup,
    Picker,
    CellGroup,
    Stepper,
    Switch,
    Icon,
    NoticeBar,
    Popover,
    Notify,
    Tag,
    CountDown,
    Dialog,
    Tab,
    Tabs,
    Loading,
    DatePicker,
    Empty,
    Skeleton,
    SkeletonImage,
    SkeletonParagraph,
    List,
    Card, Toast
} from 'vant';

createApp(App).directive('highlight',function (el) {


    let blocks = el.querySelectorAll('pre code');
    blocks.forEach((block)=>{


        hljs.highlightBlock(block)
    })
}).use(router).use(store).use(Button).use(Swipe).use(SwipeItem).use(Image)
    .use(Tabbar).use(TabbarItem).use(Field).use(Popup).use(Picker).use(CellGroup)
    .use(Stepper).use(Switch).use(FloatingBubble).use(Icon).use(NoticeBar).use(Popover).use(Uploader).use(Notify)
    .use(Tag).use(CountDown).use(Dialog).use(Tab).use(Tabs).use(Loading).use(DatePicker).use(Empty).use(Skeleton)
    .use(SkeletonImage).use(SkeletonParagraph).use(List).use(Card).use(Toast).mount('#app')
